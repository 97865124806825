import React from 'react';

import styles from './Loader.module.css';

function Loader(props) {
  return (
      <div className={ props.fullPage ? styles.Loader : styles.LoaderMini }>
        <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{ margin: "auto", background: "transparent" }}
        width="200"
        height="200"
        display="block"
        preserveAspectRatio="xMidYMid"
        viewBox="0 0 100 100"
        >
        <g transform="translate(20 50)">
        <circle r="8" fill="#f41414" transform="scale(.82649)">
            <animateTransform
            attributeName="transform"
            begin="-0.4360465116279069s"
            calcMode="spline"
            dur="1.1627906976744184s"
            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
            keyTimes="0;0.5;1"
            repeatCount="indefinite"
            type="scale"
            values="0;1;0"
            ></animateTransform>
        </circle>
        </g>
        <g transform="translate(40 50)">
        <circle r="8" fill="#f41414" transform="scale(.49158)">
            <animateTransform
            attributeName="transform"
            begin="-0.2906976744186046s"
            calcMode="spline"
            dur="1.1627906976744184s"
            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
            keyTimes="0;0.5;1"
            repeatCount="indefinite"
            type="scale"
            values="0;1;0"
            ></animateTransform>
        </circle>
        </g>
        <g transform="translate(60 50)">
        <circle r="8" fill="#f41414" transform="scale(.16013)">
            <animateTransform
            attributeName="transform"
            begin="-0.1453488372093023s"
            calcMode="spline"
            dur="1.1627906976744184s"
            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
            keyTimes="0;0.5;1"
            repeatCount="indefinite"
            type="scale"
            values="0;1;0"
            ></animateTransform>
        </circle>
        </g>
        <g transform="translate(80 50)">
        <circle r="8" fill="#f41414" transform="scale(.00013)">
            <animateTransform
            attributeName="transform"
            begin="0s"
            calcMode="spline"
            dur="1.1627906976744184s"
            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
            keyTimes="0;0.5;1"
            repeatCount="indefinite"
            type="scale"
            values="0;1;0"
            ></animateTransform>
        </circle>
        </g>
        </svg>
    </div>
  );
}

export default Loader;