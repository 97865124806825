import React from 'react';
import styles from './Navigation.module.css';
import NavigationItem from './NavigationItem/NavigationItem';

const Navigation = (props) => {
    
    const showNavigation = event => {
        const navigatoonElement = document.getElementById('navigation');
        if(navigatoonElement.classList.contains(styles.Show)) {
            navigatoonElement.classList.remove(styles.Show);
        } else {
            navigatoonElement.classList.add(styles.Show);
        }
    }
    
    return (
        <div className={ styles.Navigation }>
            <div className={ styles.Burger} onClick={ showNavigation }>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <ul id="navigation">
                <NavigationItem link="/guide"> Guide </NavigationItem>
                <NavigationItem link="/concert"> Concert </NavigationItem>
                <NavigationItem link="/compositions"> Compositions </NavigationItem>
                <NavigationItem link="/about"> About </NavigationItem>
                <NavigationItem link="/team"> Team </NavigationItem>
                <NavigationItem link="/experience"> experience </NavigationItem>
            </ul>
        </div>
    );
}

export default Navigation;