import React from 'react';
import styles from './Stair.module.css';

const Stair = (props) => (
    <div className={ styles.Stair}>
        <div id="proceed">
            <img src="/images/stairs.png" alt="stairs" />
            <p>Proceed</p>
        </div>
    </div>
);

export default Stair;