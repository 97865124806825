import React, { Component } from 'react';
import Building from '../../Components/Building/Building';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import Modal from '../../Components/UI/Modal/Modal';
import Contribute from '../Contribute/Contribute';
import HomeControls from '../../Components/HomeControls/HomeControls';
import SideButtons from '../../Components/SideButtons/SideButtons';
import axios from '../../axios-instance';
import Loader from '../../Components/UI/Loader/Loader';
import { API_URL } from '../../env';

class Home extends Component {
    state = {
        windows: [],
        voices: {},
        voiceRecordingMode: false,
        loading: true,
        loadingData: false,
        page: 1,
        noMoreData: false,
        dataCount: 0,
        shuffleMode: 0,
        composingMode: false,
        composeReady: false,
        composeStartTimeStamp: 0,
        composeDuration: 30,
        composeData: [],
        audioBlob: null,
        audioURL: null
    }

    startContributeModeHandler = () => {
        this.setState({voiceRecordingMode: true});
    }

    cancelContributeModeHandler = () => {
        this.setState({voiceRecordingMode: false});
    }

    toggleComposingModeHandler = () => {
        const currentMode = this.state.composingMode;
        
        this.setState({composingMode: !currentMode}, () => {
            if(this.state.composingMode) {
                this.stopAll();
                this.setState({composeStartTimeStamp: new Date().getTime()});
            } else {
                this.stopComposing();
            }
        });
    }

    stopComposing = () => {
        this.setState({loading: true});
        const composeDuration = (new Date().getTime() - this.state.composeStartTimeStamp) / 1000;
        const composeData = [...this.state.composeData];
        composeData.forEach(data => {
            if(data.voiceEnd === null) {
                data.voiceEnd = composeDuration;
            }
        });

        this.stopAll();
        this.setState({composeReady: true, composeData, composeDuration});
        
        const data = {
            params: {
                composeData: composeData,
                composeDuration: composeDuration
            }
        };

        fetch(API_URL + 'voices/compose', {method: "POST", body: JSON.stringify(data), headers: {"Content-Type": "application/json"}}).then(res => {
            const reader = res.body.getReader();
            const chunks = [];
            const readChunks = async () => {
                return reader.read().then(f => {
                    chunks.push(f.value);
                    if(!f.done) {
                        readChunks();
                    }
                });
            }

            
            readChunks().then(() => {
                const audioBlob = new Blob(chunks);
                const audioURL = URL.createObjectURL(audioBlob);
                this.setState({
                    audioBlob,
                    audioURL,
                    voiceRecordingMode: true,
                    loading: false
                });
            });
            
            this.setState({ loading: false });
            
        });    
    }

    getLatestData = () => {
        if(this.state.dataCount !== 0 && this.state.windows.length === this.state.dataCount) {
            this.setState({loadingData: false, noMoreData: true});
            return;
        }

        this.setState({loadingData: true});
        axios.get('/voices', {
            params: {
                page: this.state.page,
                shuffle: this.state.shuffleMode,
                compose: 0
            }
        }).then(response => {
                // this.setState({ loading: false });
                let windows = response.data.data.map(d => {
                    return {id: d.id, color: d.color.color, fileName: d.file_name}
                });
                
                let noMoreData = false;
                if(windows.length === 0) {
                    noMoreData = true;
                }

                const currentState = {...this.state};
                
                const voices = currentState.voices;
                windows.forEach(window => {
                    const audio = new Audio(API_URL + 'voices/' + window.fileName);
                    audio.onended = () => this.audioEndedHandler(window.id);
                    voices[window.id] = audio;
                });

                const currentWindows = currentState.windows;
                windows = currentWindows.concat(windows);
                
                this.setState({
                    windows, 
                    voices,
                    loading: false, 
                    loadingData: false, 
                    page: currentState.page + 1, 
                    noMoreData,
                    dataCount: response.data.total
                });
            })
            .catch(error => {
                this.setState({loadingData: false, noMoreData: true});
                this.setState({ loading: false });
            });
    }

    scrollHandler = () => {
        if(this.state.noMoreData || this.state.loadingData) {
            return;
        }
        const documentHeight = document.documentElement.offsetHeight;
        const windowHeight = window.innerHeight;
        const documentScrollTop = document.documentElement.scrollTop;
        if(documentHeight - windowHeight - documentScrollTop <= 100) {
            this.getLatestData();
        }
    }

    componentDidMount() { 
        window.addEventListener('scroll', this.scrollHandler);
        this.getLatestData();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollHandler);
        this.stopAll();
    }

    addData = (id) => {
        axios.get('/voices/get/' + id)
            .then(response => {
                const newWindow = {
                    id: response.data.id, 
                    color: response.data.color.color, 
                    fileName: response.data.file_name
                }

                const currentWindows = {...this.state}.windows;
                currentWindows.pop();
                currentWindows.unshift(newWindow);

                const voices = {...this.state}.voices;
                const audio = new Audio(API_URL + 'voices/' + newWindow.fileName);
                audio.onended = () => this.audioEndedHandler(newWindow.id);
                voices[newWindow.id] = audio;
                

                this.setState({windows: currentWindows, voices: voices});
                // setTimeout(() => {
                //     this.setState({voiceRecordingMode: false});
                // }, 3000);
                
            }).catch(error => {
                setTimeout(() => {
                    this.setState({voiceRecordingMode: false});
                }, 3000);
            })
    }

    shuffleHandler = () => {
        this.stopAll();
        this.setState({ 
            shuffleMode: this.state.shuffleMode === 1 ? 0 : 1, 
            page: 1, 
            noMoreData: false, 
            dataCount: 0, 
            windows: [] 
        }, () => {
            this.getLatestData();
        });
    }

    playHandler = (id) => {
        const voice = this.state.voices[id];
        if(this.state.composingMode) {
            this.addComposeData(id, voice);
        }
        
        voice.play();
    }

    pauseHandler = (id) => {
        const voice = this.state.voices[id];
        voice.pause();
        if(this.state.composingMode) {
            this.updateComposeData(id, voice.currentTime);
        }
    }

    addComposeData = (id, voice) => {
        const composeData = [...this.state.composeData];
        
        composeData.push({
            id: id,
            name: voice.src.substring(voice.src.lastIndexOf('/') + 1),
            start: (new Date().getTime() - this.state.composeStartTimeStamp) / 1000,
            voiceStart: Math.floor(voice.currentTime * 1000),
            voiceEnd: null
        });

        this.setState({ composeData });
    }

    updateComposeData = (id, time) => {
        const composeData = [...this.state.composeData]
        for(let i = composeData.length - 1; i >= 0; i--) {
            if(composeData[i].id === id) {
                composeData[i].voiceEnd = Math.floor(time * 1000) / 1000;
                return false;
            }
        }

        this.setState({ composeData });
    }

    audioEndedHandler = (id) => {
        const voice = this.state.voices[id];
        voice.currentTime = 0;
        if(this.state.composingMode) {
            this.updateComposeData(id, voice.duration);
            this.addComposeData(id, voice);
        }
        voice.play();
        
    }

    stopAll = () => {
        const voices = this.state.voices;
        for(let i in voices) {
            voices[i].pause();
            voices[i].currentTime = 0;
        }    
    }

    render () { 
        let contribute = null;

        if(this.state.voiceRecordingMode) {
            contribute = <Contribute 
                voiceRecordingMode={ this.state.voiceRecordingMode } 
                audioBlob={ this.state.audioBlob }
                audioURL={ this.state.audioURL }
                addData={ this.addData } 
            />
        }

        let content = (
            <Aux>
                <div className="main-page">
                    <Modal show={ this.state.voiceRecordingMode } modalClosed={ this.cancelContributeModeHandler }>
                        { contribute }
                    </Modal>
                    <div>
                        <div style={{margin: "0 auto", maxWidth: "1140px"}}>
                            <HomeControls 
                                startContributeMode={ this.startContributeModeHandler }
                                startComposingMode={ this.toggleComposingModeHandler }
                                composingMode={ this.state.composingMode }
                            />
                            <Building 
                                windows={ this.state.windows } 
                                playClicked={ this.playHandler }
                                pauseClicked={ this.pauseHandler }
                                composingMode={ this.state.composingMode }
                            />
                            <SideButtons 
                                shuffleMode = { this.state.shuffleMode === 1 ? true : false}
                                shuffleClicked = { this.shuffleHandler }
                            />
                        </div>
                    </div>
                    {this.state.loadingData ? <Loader fullPage={ false } /> : null}
                </div>    
            </Aux>
        );

        if(this.state.loading) {
            content = <Loader fullPage={ true } />;
        }
        
        
        return (
            <Aux>
                { content }
            </Aux>
        );
    }
}

export default Home;