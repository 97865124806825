import React from 'react';

import styles from './Experience.module.css';

const Experience = (props) => (
    <div className={ styles.Experience }>
        <iframe title="Form" src="https://docs.google.com/forms/d/e/1FAIpQLSdesH3AOYCh_Y07K3LXNpsUcrlVU4B2PGUaTkPR-c7CxQllfg/viewform?embedded=true" width="640" height="6159" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </div>
);

export default Experience;