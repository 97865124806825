import React, { Component } from 'react';
import Building from '../../Components/Building/Building';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import SideButtons from '../../Components/SideButtons/SideButtons';
import axios from '../../axios-instance';
import Loader from '../../Components/UI/Loader/Loader';
import { API_URL } from '../../env';

class Compositions extends Component {
    state = {
        windows: [],
        voices: {},
        voiceRecordingMode: false,
        loading: true,
        loadingData: false,
        page: 1,
        noMoreData: false,
        dataCount: 0,
        shuffleMode: 0,
        composingMode: false,
        composeReady: false,
        composeStartTimeStamp: 0,
        composeDuration: 30,
        composeData: [],
        audioBlob: null,
        audioURL: null
    }

    
    getLatestData = () => {
        if(this.state.dataCount !== 0 && this.state.windows.length === this.state.dataCount) {
            this.setState({loadingData: false, noMoreData: true});
            return;
        }

        this.setState({loadingData: true});
        axios.get('/voices', {
            params: {
                page: this.state.page,
                shuffle: this.state.shuffleMode,
                compose: 1
            }
        }).then(response => {
                // this.setState({ loading: false });
                let windows = response.data.data.map(d => {
                    return {id: d.id, color: d.color.color, fileName: d.file_name}
                });
                
                let noMoreData = false;
                if(windows.length === 0) {
                    noMoreData = true;
                }

                const currentState = {...this.state};
                
                const voices = currentState.voices;
                windows.forEach(window => {
                    const audio = new Audio(API_URL + 'voices/' + window.fileName);
                    audio.onended = () => this.audioEndedHandler(window.id);
                    voices[window.id] = audio;
                });

                const currentWindows = currentState.windows;
                windows = currentWindows.concat(windows);
                
                this.setState({
                    windows, 
                    voices,
                    loading: false, 
                    loadingData: false, 
                    page: currentState.page + 1, 
                    noMoreData,
                    dataCount: response.data.total
                });
            })
            .catch(error => {
                this.setState({loadingData: false, noMoreData: true});
                this.setState({ loading: false });
            });
    }

    scrollHandler = () => {
        if(this.state.noMoreData || this.state.loadingData) {
            return;
        }
        const documentHeight = document.documentElement.offsetHeight;
        const windowHeight = window.innerHeight;
        const documentScrollTop = document.documentElement.scrollTop;
        if(documentHeight - windowHeight - documentScrollTop <= 100) {
            this.getLatestData();
        }
    }

    componentDidMount() { 
        window.addEventListener('scroll', this.scrollHandler);
        this.getLatestData();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollHandler);
        this.stopAll();
    }

    

    shuffleHandler = () => {
        this.stopAll();
        this.setState({ 
            shuffleMode: this.state.shuffleMode === 1 ? 0 : 1, 
            page: 1, 
            noMoreData: false, 
            dataCount: 0, 
            windows: [] 
        }, () => {
            this.getLatestData();
        });
    }

    playHandler = (id) => {
        const voice = this.state.voices[id];
        voice.play();
    }

    pauseHandler = (id) => {
        const voice = this.state.voices[id];
        voice.pause();
    }

    

    audioEndedHandler = (id) => {
        const voice = this.state.voices[id];
        voice.currentTime = 0;
        voice.play();
        
    }

    stopAll = () => {
        const voices = this.state.voices;
        for(let i in voices) {
            voices[i].pause();
            voices[i].currentTime = 0;
        }    
    }

    render () {     
        let content = (
            <Aux>
                <div className="main-page">
                    <div>
                        <div style={{margin: "0 auto", maxWidth: "1140px"}}>
                            <Building 
                                windows={ this.state.windows } 
                                playClicked={ this.playHandler }
                                pauseClicked={ this.pauseHandler }
                                composingMode={ this.state.composingMode }
                            />
                            <SideButtons 
                                shuffleMode = { this.state.shuffleMode === 1 ? true : false}
                                shuffleClicked = { this.shuffleHandler }
                            />
                        </div>
                    </div>
                    {this.state.loadingData ? <Loader fullPage={ false } /> : null}
                </div>    
            </Aux>
        );

        if(this.state.loading) {
            content = <Loader fullPage={ true } />;
        }
        
        
        return (
            <Aux>
                { content }
            </Aux>
        );
    }
}

export default Compositions;