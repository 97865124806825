import React from 'react';
import styles from './About.module.css';

const About = (props) => (
    <div className={ styles.About }>
        <div className={ styles.AboutTop }>
            <h2>Voice Yard is an online space created to encourage people to listen and be heard.</h2>
            <div className={ styles.AboutTopLeft }>
                <p>Used to perceiving our world mostly visually, we sometimes forget that sound is another
                important means of perception and communication. Every object reveals itself not only through
                its shapes and colors, but also through sounds, sometimes even more telling about its essence.
                We, humans, have always used our voices as an important means of self-expression and communication.
                There are familiar metaphors revealing its existential importance, such as “inner voice” and “voice
                of the heart,” referring to an intimate “true nature” inherent in this human ability.</p>
            </div>
            <div className={ styles.AboutTopRight }>
                <p>Using voice for everyday communication is as natural as breathing or walking, so we seldom
                pay much attention to the way we sound, and how we, or others, feel about it. But this changes
                when it comes to recorded voices. When there is no other representation but the sound alone,
                it suddenly merits close attention, and acquires special meaning in itself.</p>
                <p>Sound recordings are a good way to face voices, to feel more deeply what sound brings
                to our comprehension. But at the same time they can create the frustrating situation of hearing
                one's own voice, or even voices of very familiar people, sounding differently from what our ears
                are used to “in reality.”</p>
            </div>
        </div>
        <div className={ styles.AboutMiddle }>
            <div className={ styles.AboutMiddleLeft }>
                <div className={ styles.AboutMiddleLeftInside}>
                    <h2>QUESTIONS</h2>
                    <p>So, can technology bring us closer to hearing our inner voices, or does it alienate us
                    from them? What role does voice play in the modern world, where online communication holds
                    the same (if not greater) importance as the physical kind? Can we learn more about ourselves,
                    and people around, by being more careful and attentive to the way we sound? What does it mean
                    – to un-mute one's voice?</p>
                    <p>These questions arose especially sharply these days – in a world paralyzed with quarantine
                    measures, entrapping people within the walls of their dwellings, closing their mouths with
                    masks, and forcing communication into online spaces. Conversations are forced into webcams,
                    mobile calls and messenger apps. Maybe now is a good time to listen closely, and try to hear
                    some answers that voices can give us?</p>
                </div>
            </div>
            <div className= { styles.AboutMiddleRight }>
                <div className= { styles.AboutMiddleRightInside }>
                    <p>With these thoughts in mind, Voice Yard was created. We want to hear voices, we encourage
                    people to feel connected through sound: with themselves, and with others. We believe that an
                    atmosphere of playful creativity, which we enable through this interactive platform, is the
                    best environment for free self-expression, interested listening, the joy of creating something
                    together with others, and even, establishing some new kind of unusual indirect communication.</p>
                    <h2>Can we build community, through sharing our voices?</h2>
                    <p>Let's begin recording and see!</p>
                </div>
            </div>
        </div>
        <div className={ styles.AboutBottom }>
           <div className={ styles.AboutBottomTitle}>
            <h2>Closer look</h2>
            </div>
           <div className={ styles.AboutBottomLeft }>
            <p>Everyone knows that voice was the first musical instrument. Thinking further, the philosopher
            Don Ihde proposed that every sound in the world can be a musical instrument, which resonates with
            the principles of Musique Concrète, one of the foundations of electronic music. Inspired by these
            ideas, we invite people to create a collective instrument for music-making – a sound board
            consisting of many voices that can be combined and remixed into a “music piece” - a remix? a choir?
            a symphony? Every participant has the chance to become instrument and composer at the same time,
            to be both inside and out of the piece of music created by him/herself.</p>
           </div>
           <div className={ styles.AboutBottomCenter }>
               <p>The definition of “voice” is not restricted in this “game,” everyone is free to use his/her
                own voice as well as any other sounds, as long as those sounds reflect his/her mood, emotion,
                state of mind. We invite participants to record the impression of their day – something simple
                but representative, ever-changing, but every time important.</p>
                <p>The result, as shown and experienced here, will be a sounds grid – every participant will
                add a window to his/her soul, contributing to the building of a kind of house. The windows can
                be “opened” one by one, or in a group, in different combinations – to fill the yard with voices,
                entangled with each other in unpredictable sound constellations, or maybe – conversations?</p>
           </div>
           <div className={ styles.AboutBottomRight }>
               <p>The beauty of this game is in its unpredictable results. More premises we do – more questions arise.
                But we will never get any answers if we don’t act. So, we hope you feel inspired to try, and that playing
                in our voice yard will bring you some joy and maybe help to find some unexpected answers, even if there
                were no questions before. Let's play the instruments of our hearts, together!</p>
           </div>
        </div>
    </div>
);

export default About;