import React from 'react';
import styles from './SideButtons.module.css';


const SideButtons = (props) => {
    const scrollToTop = () => {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
            window.requestAnimationFrame(scrollToTop);
            window.scrollTo(0, c - c / 8);
        }
    };

    let shuffleButton = <img src="/images/icons/shuffle.png" alt='shuffle icon' onClick= { props.shuffleClicked}></img>;
    if (props.shuffleMode) {
        shuffleButton = <img src="/images/icons/shuffle_red.png" alt='shuffle icon' onClick= { props.shuffleClicked}></img>;
    }

    return(
        <div className={ styles.SideButtons }>
            { shuffleButton }
            <img src="/images/stairs.png" alt='stairs' onClick={ scrollToTop }></img>
        </div>
    );
}

export default SideButtons;