import React from 'react';

import styles from './Window.module.css';


const window = (props) => {
    const defaultSrc = `/images/frames/window.png`;
    const colorSource = `/images/frames/window_${ props.color }.png`;

    function playHandler(event) {
        event.target.style.display = 'none';
        event.target.nextSibling.style.display = "block";
        props.playClicked(props.id)
    }

    function pauseHandler(event) {
        event.target.style.display = 'none';
        event.target.previousSibling.style.display = "block";
        props.pauseClicked(props.id)
    }

    return(
        <div className={ styles.Window }>
            <img src={ defaultSrc } alt="blue window" onClick={ playHandler }/>
            <img src={ colorSource } alt={ `${props.color} window` } onClick={ pauseHandler } />
        </div>
    );
}


export default window;