import React, { Component } from 'react';
import styles from './Team.module.css';
import Window from '../../Components/Building/Window/Window';
import { API_URL } from '../../env';

class Team extends Component {
    state = {
        windows:  [
            {id: 0, color: "blue", fileName: "voice_maryam"},
            {id: 1, color: "green", fileName: "voice_diana"},
            {id: 2, color: "yellow", fileName: "voice_olya"},
            {id: 3, color: "orange", fileName: "voice_riad"},
            {id: 4, color: "red", fileName: "voice_vakho"},
        ],
        voices: {}
    }

    componentDidMount() {
        const windows = this.state.windows;
        const voices = this.state.voices;

        windows.forEach(window => {
            const audio = new Audio(API_URL + 'voices/' + window.fileName);
            audio.loop = true;
            voices[window.id] = audio;
        });

        this.setState({voices});
    }

    componentWillUnmount() {
        this.stopAll();
    }

    playHandler = (id) => {
        this.state.voices[id].play();
    }

    pauseHandler = (id) => {
        this.state.voices[id].pause();
    }

    stopAll = () => {
        const voices = this.state.voices;
        for(let i in voices) {
            voices[i].pause();
            voices[i].currentTime = 0;
        }    
    }

    render() {
        return (
            <div className={ styles.Team}>
                <div className={ styles.Members}>
                    <div className={ styles.MembersWindow}>
                        <Window 
                            {...this.state.windows[0]}
                            playClicked = { this.playHandler } 
                            pauseClicked = { this.pauseHandler } 
                        />
                        <span>maryam</span>
                        <span>mumladze</span>
                        <div className={ styles.SocialIcons}>
                            <a rel="noopener noreferrer" href="https://www.facebook.com/MaryamMumladze/" target="_blank"><img src="/images/icons/fb_icon.png" alt='facebook icon'></img></a>
                            <a rel="noopener noreferrer" href="https://www.instagram.com/maryamhontas/" target="_blank"><img src="images/icons/insta_icon.png" alt='instagram icon'></img></a>
                        </div>
                    </div>
                    <div className={ styles.MembersWindow}>
                        <Window 
                            {...this.state.windows[1]}
                            playClicked = { this.playHandler } 
                            pauseClicked = { this.pauseHandler } 
                        />
                        <span>diana</span>
                        <span>pankova</span>
                        <div className={ styles.SocialIcons}>
                            <a rel="noopener noreferrer" href="https://www.facebook.com/diana.pankova.35/" target="_blank"><img src="/images/icons/fb_icon.png" alt="facebook icon"></img></a>
                            <a rel="noopener noreferrer" href="https://www.instagram.com/maverick_mariner/" target="_blank"><img src="images/icons/insta_icon.png" alt='instagram icon'></img></a>
                        </div>
                    </div>
                    <div className={ styles.MembersWindow}>
                        <Window 
                            {...this.state.windows[2]}
                            playClicked = { this.playHandler } 
                            pauseClicked = { this.pauseHandler } 
                        />
                        <span>olya</span>
                        <span>pylypenko</span>
                        <div className={ styles.SocialIcons}>
                            <a rel="noopener noreferrer" href="https://www.facebook.com/profile.php?id=100008063267721/" target="_blank"><img src="/images/icons/fb_icon.png" alt="facebook icon"></img></a>
                            <a rel="noopener noreferrer" href="https://open.spotify.com/user/21qgdoffadknbop63s7unt47a?si=fo3bc3YkSkqGAFsfk8xXKg/" target="_blank"><img src="/images/icons/spotify_icon.png" alt=""></img></a>
                        </div>
                    </div>
                    <div className={ styles.MembersWindow}>
                        <Window 
                            {...this.state.windows[3]}
                            playClicked = { this.playHandler } 
                            pauseClicked = { this.pauseHandler } 
                        />
                        <span>riad</span>
                        <span>salameh</span>
                        <div className={ styles.SocialIcons}>
                            <a rel="noopener noreferrer" href="https://www.facebook.com/riad.salameh/" target="_blank"><img src="/images/icons/fb_icon.png" alt="facebook icon"></img></a>
                            <a rel="noopener noreferrer" href="https://www.instagram.com/salamehriad/" target="_blank"><img src="images/icons/insta_icon.png" alt='instagram icon'></img></a>
                        </div>
                    </div>
                    <div className={ styles.MembersWindow}>
                        <Window 
                            {...this.state.windows[4]}
                            playClicked = { this.playHandler } 
                            pauseClicked = { this.pauseHandler } 
                        />
                        <span>vakho</span>
                        <span>nakashidze</span>
                        <div className={ styles.SocialIcons}>
                            <a rel="noopener noreferrer" href="https://www.facebook.com/nicutm/" target="_blank"><img src="/images/icons/fb_icon.png" alt="facebook icon"></img></a>
                            <a rel="noopener noreferrer" href="https://www.linkedin.com/in/vakho-nakashidze-239a62146/" target="_blank"><img src="images/icons/linked_icon.png" alt='LinkedIn icon'></img></a>
                        </div>
                    </div>
                </div>
                <div className={ styles.SocialFooter}>
                    <div>
                        <img src="images/icons/insta_icon.png" alt='instagram icon'></img>
                        <img src="/images/icons/fb_icon.png" alt="facebook icon"></img>
                    </div>
                    <p>voice yard</p>
                </div>
            </div>
        );
    }
} 

export default Team;