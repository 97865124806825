import React from 'react';
import styles from './Guide.module.css';
import { useHistory } from "react-router-dom";
import Stair from "../Stair/Stair";

const Guide = (props) => {
    const history = useHistory();
    
    function goToGuide(event) {
        history.push("/concert");
    }

    return (
        <div className={ styles.Guide }  >
            <div className={ styles.WelcomeToGuideLeft}>
                <h2>Hi!<br/> Welcome to voice yard – a digital space dedicated to listening!</h2>
                <p>
                    We invite you to have some sonic fun in our playground – 
                    join with a simple act of self-expression, listening, composing.  
                </p>
            </div>
            <div className={ styles.WelcomeToGuideRight}>
                <div className={ styles.UsingSteps}>
                    <div>
                        <span>01</span>
                        <p>"Sum up" your day in sound</p>
                    </div>
                    <div>
                        <span>02</span>
                        <p>Create/Upload the recording</p>
                    </div>
                    <div>
                        <span>03</span>
                        <p>Choose the color of your window</p>
                    </div>
                    <div>
                        <span>04</span>
                        <p>Play around with other voices</p>
                    </div>
                    <div>
                        <span>05</span>
                        <p>Save your remix and share it</p>
                    </div>
                </div>
                <div className={ styles.UsingStepsBottom}>
                    <p>If this game will bring you some new impressions
                    – please, share them with us, to help us understand the project better!</p>
                    <p>But, most importantly -</p>
                    <h2  onClick={ goToGuide } >TALK TO US <br/>and <br/>TO THE WORLD!</h2>
                </div>
            </div>
            <div className={ styles.RedBorder}>
                <div></div>
            </div>
            <div className={ styles.GuideBottom}>
                <div className={ styles.Title}>
                    <h2>What is the voice of someone's soul?</h2>
                </div>
                <div className={ styles.TitleInfo}>
                    <div className={ styles.InfoLeft}>
                        <p>We can search for the answer to this question for our whole life, and never catch it.
                        Seeking eternal truths, or getting lost in daily routines,
                        we often forget that our true nature is built through time,
                        patch by patch, that it consists of the moments, emotions and impressions of every day.
                        So often we keep it inside – this "feeling of life," swallowing it without attention.
                        And so often, nowadays we force our self-expression into “text message” format,
                        forgetting how we sound and how we really feel.
                        </p>
                        <p>If we expressed ourselves aloud more – maybe it would not only bring the pleasure
                        of being heard but also help us to hear ourselves better,<span>unmute the voice of the heart!</span> 
                        </p>
                    </div>
                    <div className={ styles.InfoRight}>
                        <div>
                            <p>What could happen if we shared it with the wider world?
                            Instead of writing posts and text messages, let our actual voice sound through the global net,
                            for strangers eager to listen, and share their emotions too?</p>
                        </div>
                        <div>
                            <p>What if we don’t just listen, but play together, combine our emotions into a collective melody,
                            merge with others in a choir (or symphony)? Transform our voices into musical instruments?</p>
                        </div>
                        <div>
                            <p>For this, we invite you to reflect on your day. 
                            <span> How are you feeling today? Was there something that captivated your mind?
                            Something that annoyed you? Were you struck by some insight?</span></p>
                        </div>
                        <div className={ styles.InfoRightBottom}>
                            <span>What else would you like to share?</span>
                            <p>We want to hear it! Talking, whistling, screaming, laughing, a sigh, a poem, a melody,
                            a noise – any sound is good if you can associate your state of mind with it.
                            You can record an immediate response directly, or use a recording from your device.</p>
                        </div>
                        <div className={ styles.Stair } onClick={ goToGuide }>
                            <Stair />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
} 

export default Guide;