import React from 'react';

import styles from './Building.module.css';
import Window from './Window/Window';

const building = (props) => {
    
    const windows = props.windows.map( window => {
        return (
            <Window 
                {...window} 
                key={ window.id } 
                playClicked = { props.playClicked } 
                pauseClicked = { props.pauseClicked } 
            /> 
        ) 
    });

    let composingModeClassName = null;
    if(props.composingMode) {
        composingModeClassName = styles.Red;
    }
    
    return(
        <div className={ [styles.Building, composingModeClassName].join(" ") }>
            <div className={ styles.Floors } >
                { windows }    
            </div>
        </div>
    );
}

export default building;