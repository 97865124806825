import React from 'react';
import WaveSurferContainer from './WaveSurferContainer/WaveSurferContainer';
import Button from '../UI/Button/Button';
import Loader from '../UI/Loader/Loader';

import styles from './Recording.module.css';

const recording = (props) => {

    let title = "What's on your mind?";
    if(props.voicePicked) {
        title = <h2> Select your color </h2>;
    }

    if(props.dataReady) {
        title = <h2> All Done! </h2>;
    } 

    if(props.contributeStatus === 0){
        title = <h2 className={ styles.ErrorMessage }> Something went wrong. Please, Try again Later... </h2>
    }

    if(props.contributeStatus === 2){
        title = <h2 className={ styles.SuccessMessage }> Thanks for your contribution </h2>
    }

    let playButton = null;
    if(props.voiceReady) {
        playButton = <img src="/images/icons/play.png" onClick={ props.playClicked } alt="Play"/>;
        if(props.voicePlaying) {
            playButton = <img src="/images/icons/pause.png" onClick={ props.playClicked } alt="Play"/>;
        }
    }

    let wrapper = (
        <div className={ styles.WaveformWrapper }>
            <WaveSurferContainer />
            { playButton }
        </div>
    );

    if(props.voicePicked) {
        wrapper = (
            <div className={ styles.ColorPickerWrapper }>
                <div className={styles.Red} data-color="red" onClick={ (event) => props.colorClick(event, styles.Active) }>
                    <img src="/images/red.png" alt="red window"></img>
                </div>
                <div className={styles.Orange} data-color="orange" onClick={ (event) => props.colorClick(event, styles.Active) }>
                    <img src="/images/orange.png" alt="orange window"></img>
                </div>
                <div className={styles.Yellow} data-color="yellow" onClick={ (event) => props.colorClick(event, styles.Active) }>
                    <img src="/images/yellow.png" alt="yellow window"></img>
                </div>
                <div className={styles.Green} data-color="green" onClick={ (event) => props.colorClick(event, styles.Active) }>
                    <img src="/images/green.png" alt="green window"></img>
                </div>
                <div className={styles.Blue} data-color="blue" onClick={ (event) => props.colorClick(event, styles.Active) }>
                    <img src="/images/blue.png" alt="blue window"></img>
                </div>
                <div className={styles.Purple} data-color="purple" onClick={ (event) => props.colorClick(event, styles.Active) }>
                    <img src="/images/purple.png" alt="purple window"></img>
                </div>
            </div>
        );
    }

    if(props.contributeStatus !== 1) {
        wrapper = null;
    }

    if(props.loading) {
        wrapper = <Loader fullPage={ false }/>
    }

    let editButton = null;
    if(props.voicePicked && false) {
        editButton = <span onClick={ props.editClicked }>Edit</span>;
    }

    let recordButton = null;
    if(!props.voicePicked && !props.composeMode) {
        recordButton = <img src="/images/icons/record.png" onClick={ props.recordClicked } alt="Record"/>;
        if(props.voiceRecording) {
            recordButton = <img src="/images/icons/record.png" onClick={ props.stopRecordingClicked } alt="Record"/>;
        }
    }
    

    let uploadButton = null;
    if(!props.voiceRecording && !props.voiceReady && !props.voicePicked) {
        uploadButton = (
            <div className={ styles.Upload}>
                <img src="/images/icons/upload.png" alt="upload" onClick={ props.uploadClicked } />
                <span onClick={ props.uploadClicked } >Upload</span>
                <input type="file" name="uploaded_file" onChange={props.fileUploaded} />
            </div>
        );
    }

    let contributeButton = null;
    if(props.colorPicked) {
        contributeButton = <Button clicked={ props.contributeClicked }>Contribute</Button>
    }

    if(props.contributeStatus !== 1) {
        contributeButton = null;
    }

    let readyButton = null; 
    if(props.voiceReady && !props.voicePicked) {
        if(!props.voicePicked) {
            readyButton = (
                <img src="/images/icons/done.png" onClick={ props.regionCropClicked } alt="Save Selection"/>
            );
        }
    }

    let downloadButton = null; 
    if(props.voicePicked && props.contributeStatus === 2) {
        downloadButton = (
            <a href={ props.audioURL } target="_blank" rel="noopener noreferrer" className={ styles.Download}>
                <img src="/images/icons/download.png" alt="download"/>
                <span>Download</span>
            </a>
        );
    }

    let shareButton = null;
    if(false) {
        shareButton = <img src="images/icons/link.png" alt="link"/>;
    }

    return (
        <div className={ styles.Recording }>
            <div className={ styles.Title }>
                { title }
            </div>
            { wrapper }
            <div className={ styles.Controls }>
                <div>
                    { editButton }
                </div>
                <div>
                    { recordButton }
                    { contributeButton }
                </div>    
                <div className={ styles.DoneButton}>
                    { readyButton }
                </div>
            </div>
            <div className={ styles.Controls}>
                <div></div>
                <div className={ styles.ManageButtons}>
                    { uploadButton }
                    { downloadButton }
                </div>
                <div>
                    { shareButton }
                </div>
            </div>
        </div>
    );
}

export default recording;