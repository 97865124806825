import React from 'react';
import styles from './Welcome.module.css';
import Logo from '../Logo/Logo';
import { useHistory } from "react-router-dom";
import Stair from "../Stair/Stair";

const Welcome = (props) => {
    const history = useHistory();
    
    function goToGuide(event) {
        history.push("/guide");
    }

    return (
        <div className={ styles.Welcome } id="welcome">
            <div className={ styles.Moon }>
                <Logo/>
            </div>
            <div className={ styles.WelcomeContent } onClick={ goToGuide }>
                <div className={ styles.WindowHead }>
                    <h2>What's on your mind?</h2>
                </div>
                <div className={ styles.Window }>
                    <div className={ styles.YellowWindow}>
                        <img src="./images/frames/window_welcome.png" alt="welcome window"/>
                            <h2>Welcome</h2>
                    </div>
                    <Stair />
                </div>
            </div>
        </div>
    );
} 

export default Welcome;