import React, { Component } from 'react';
import Recording from '../../Components/Recording/Recording';
import VoiceRecorder from '../../Helpers/VoiceRecorder';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/src/plugin/regions';
import MicrophonePlugin from 'wavesurfer.js/src/plugin/microphone';
import toWav from 'audiobuffer-to-wav';
import axios from '../../axios-instance';
import { API_URL } from '../../env';
// import { useHistory } from "react-router-dom";

var audioBufferSlice = require('audiobuffer-slice');

class Contribute extends Component {
    voiceRecorder = null;
    waveSurfer = null;
    voiceDuration = 30000;
    waveFormElement = null;
    region = null;
    audioURL = null;
    audioBLob = null;
    cropClicked = false;
    
    userData = {
        voice: null,
        color: null
    };

    state = { 
        voicePlaying: false,
        voiceRecording: false,
        voiceReady: false,
        audioBlob: null,
        voicePicked: false,
        colorPicked: false,
        dataReady: false,
        contributeStatus: 1,
        loading: false
    }

    static getDerivedStateFromProps(props, state) {
        if(!props.voiceRecordingMode) {
            state.voicePlaying = false;
        }
        return state;
    }

    componentDidMount() {
        this.initWaveSurfer();
    }

    componentWillUnmount() {
        this.waveSurfer.pause();
    }

    componentDidUpdate(prevProps, prevState, snapShot) {
        
    }

    initWaveSurfer = () => {
        this.waveFormElement = document.getElementById('waveform');
        
        if(this.waveSurfer !== null){
            return;
        }   

        this.waveSurfer = WaveSurfer.create({
            container     : '#waveform',
            waveColor     : '#fff',
            progressColor: '#4cb6d4',
            backgroundColor: '#212057',
            interact: false,
            responsive: true,
            plugins: [
                MicrophonePlugin.create(),
                RegionsPlugin.create({
                    color: '#fff'
                }),
            ]
        });

        this.registerWaveSurferEvents();
        
        if(this.props.audioURL !== null) {
            this.waveSurfer.microphone.stop();
            this.audioBlob = this.props.audioBlob;
            this.audioURL = this.props.audioURL;
            this.waveSurfer.load(this.audioURL);
            this.userData.voice = this.audioBLob;
            this.waveSurfer.params.interact = true;
            this.setState({voiceReady: true, voiceRecording: false});
            this.waveSurfer.load(this.props.audioURL);
        }
    }

    registerWaveSurferEvents() {
        this.waveSurfer.on('finish', () => {
            this.setState({voicePlaying: false});    
        });

        this.waveSurfer.on('ready', () => {
            if(this.cropClicked) {
                if(this.waveSurfer.getDuration() > 30) {
                    alert('Please crop no more than 30 seconds');
                } else {
                    this.setState({voicePicked: true});
                }
                this.region.remove();
                this.region = null;
            }

            if(this.state.voiceReady && !this.state.voicePicked) {
                this.addRegion();
            }
        });

        this.waveSurfer.on('region-updated', (region) => {
            this.waveSurfer.pause();
            
            this.setState({voicePlaying: false});
        });
    }

    
    playHandler = () => {
        if(this.state.voiceRecording) {
            return;
        }

        const voicePlaying = !this.state.voicePlaying;
        
        if(voicePlaying) {
            if(this.state.voicePicked ) {
                this.waveSurfer.play();
            } else {
                this.region.play();
            }
            
        } else {
            this.waveSurfer.pause();
            
        }
        
        this.setState({voicePlaying: voicePlaying});
    }

    recordHandler = () => {
        this.setState({voiceRecording: true, voicePlaying: false, voiceReady: false});
        new VoiceRecorder().then(voiceRecorder => {
            this.startRecording(voiceRecorder);
        });
    }

    startRecording = voiceRecorder => {
        this.voiceRecorder = voiceRecorder;    
        // this.waveSurfer.empty();
        if(this.region !== null) {
           
            this.region.remove();
            this.region = null;
        }
        this.waveSurfer.microphone.gotStream(voiceRecorder.stream);
        this.voiceRecorder.start();

        this.voiceRecorder.sleep(this.voiceDuration).then(voiceRecorder => {
            if(voiceRecorder === false) {
                return;
            }
            this.voiceRecorder.stop().then(resolve => {
                this.stopRecording();
            });
        });
    }

    addRegion() {
        this.waveSurfer.addRegion({
            start: 0,
            end: Math.floor(this.waveSurfer.getDuration() * 1000) / 1000,
            color: "rgba(0, 255, 0, 0.3)"
        });
        
        
        const regionsList = this.waveSurfer.regions.list;
        this.region = regionsList[Object.keys(regionsList)[0]];
        this.region.on('out', () => {
            this.setState({voicePlaying: false});
        });
    }

    stopRecording() {
        this.waveSurfer.microphone.stop();

        this.setState({voiceReady: true, voiceRecording: false});
        this.audioURL = this.voiceRecorder.audioURL;
        this.audioBlob = this.voiceRecorder.audioBlob;
        this.waveSurfer.load(this.voiceRecorder.audioUrl);
        this.userData.voice = this.voiceRecorder.audioBlob;
        this.waveSurfer.params.interact = true;
        
    }

    stopRecordingHandler = () => {
        this.voiceRecorder.forceStop = true;
        this.voiceRecorder.stop().then(voiceRecorder => {
            this.stopRecording();
        });
    }

    uploadHandler = (event) => {
        event.target.parentElement.querySelector('input').click();
    }

    fileUploadedHandler = (event) => {
        const blob = event.target.files[0];
        
        if(blob.type !== "audio/mpeg") {
            alert('Please upload mp3 file');
            window.location.reload(false);
        }
        const audioUrl = URL.createObjectURL(blob);
        this.waveSurfer.microphone.stop();
        this.audioBlob = blob;
        this.audioURL = audioUrl;
        this.waveSurfer.load(audioUrl);
        this.userData.voice = blob;
        this.waveSurfer.params.interact = true;
        this.setState({voiceReady: true, voiceRecording: false});
    }

    editHandler = () => {
        this.setState({voicePicked: false});
        this.addRegion();
    }

    contributeHandler = () => {
        this.setState({loading: true})
        const formData = new FormData();
        formData.append('color', this.userData.color);
        formData.append('data', this.userData.voice);
        formData.append('compose', this.props.audioURL !== null ? 1 : 0);
        axios.post('/voices', formData).then(response => {
            this.setState({loading: false});
            if(response.data.status === "ok") {
                this.audioURL = API_URL + response.data.audio_url;
                this.setState({contributeStatus: 2, loading: false});
                if(this.props.audioURL === null) {
                    this.props.addData(response.data.id);
                }
                
            } else {
                this.setState({contributeStatus: 0, loading: false});
            }
        });
    }

    cropHandler = () => {
        this.cropClicked = true;
        const regionStart = Math.floor(this.region.start * 1000);
        const regionEnd = Math.floor(this.region.end * 1000);
    
        const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
        window.audioCtx = audioCtx;

        this.audioBlob.arrayBuffer().then(arrBuff => {
            audioCtx.decodeAudioData(arrBuff, audioBuffer => {
                audioBufferSlice(audioBuffer, regionStart, regionEnd, (error, slicedAudioBuffer) => {
                    if (error) {
                        console.error(error);
                    } else {
                        const wav = toWav(slicedAudioBuffer);
                        const blob = new Blob([new Uint8Array(wav, 0, wav.byteLength)]);
                        this.setState({voicePlaying: false});
                        const audioUrl = URL.createObjectURL(blob);
                        this.audioBlob = blob;
                        this.audioUrl = audioUrl;
                        this.waveSurfer.load(audioUrl);
                        this.userData.voice = blob;
                          
                    }
                });
            });
        });
    }

    colorClickHandler = (event, activeClassName) => {
        const activeElement = event.target.parentElement.parentElement.querySelector("." + activeClassName);
        
        if(activeElement !== null) {
            activeElement.classList.remove(activeClassName);
        }
            
        event.target.parentElement.classList.add(activeClassName);
        this.userData.color = event.target.parentElement.dataset.color;
        this.setState({colorPicked: true});
    }

    colorPickedHandler = () => {
        this.setState({dataReady: true});
    }

    render () {
        return (
            <Recording 
                playClicked={ this.playHandler } 
                recordClicked={ this.recordHandler } 
                uploadClicked={ this.uploadHandler } 
                editClicked={ this.editHandler } 
                contributeClicked={ this.contributeHandler } 
                stopRecordingClicked={ this.stopRecordingHandler }
                regionCropClicked = { this.cropHandler }
                colorClick = { this.colorClickHandler }
                colorPickedClicked = { this.colorPickedHandler }
                fileUploaded = { this.fileUploadedHandler }
                voiceReady={ this.state.voiceReady }
                voiceRecording= { this.state.voiceRecording } 
                voicePlaying= { this.state.voicePlaying } 
                voicePicked = { this.state.voicePicked }
                colorPicked = { this.state.colorPicked }
                dataReady = { this.state.dataReady }
                contributeStatus = { this.state.contributeStatus }
                loading = { this.state.loading }
                audioURL = { this.audioURL }
                composeMode = {this.props.audioURL ? true : false}
            />
        )
    }
}

export default Contribute;