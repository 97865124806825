import React from 'react';

import styles from './WaveSurferCintainer.module.css';

const waveSurferContainer = (props) => { 
    return (
        <div className={ styles.WaveForm } id="waveform"></div>
    )
};

export default React.memo(waveSurferContainer);