import React from 'react';

import styles from './ComposeButton.module.css';

const ComposeButton = (props) => {
    let recordButtonImg = <img src="images/record.png" alt="compose"/>;
    
    const recordButtonImgRed = <img 
        src="images/record_red.png" 
        alt="compose"
        className={props.composingMode ? styles.Blink : styles.Hide}
    />;

    if(props.composingMode) {

    }
    
    return (
        <div onClick={ props.clicked } className={ styles.ComposeButton }>
            { recordButtonImg }
            { recordButtonImgRed }
            {/* <span className={props.composingMode ? styles.Red : null}>Compose</span> */}
            <span>Compose</span>
        </div>
    );
}

export default ComposeButton;