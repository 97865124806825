import React from 'react';
import Moon from './Moon/Moon';
import styles from './Logo.module.css';

const Logo = (props) => (
    <div className={ styles.Logo}>
        <a href="/concert">
            <Moon/>
            <div className={ styles.Caption }>
                <p>Voice</p>
                <p>Yard</p>
            </div>
        </a>
    </div>
);

export default Logo;