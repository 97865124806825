import React from 'react';
import styles from './HomeControls.module.css';
import ContributeButton from './ContributeButton/ContributeButton';
import ComposeButton from './ComposeButton/ComposeButton';

const HomeControls = (props) => (
    <div className={ styles.HomeControls }>
        <ContributeButton clicked={ props.startContributeMode }/>
        <ComposeButton clicked={ props.startComposingMode } composingMode={ props.composingMode } />
    </div>
    );

export default HomeControls;