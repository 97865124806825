class VoiceRecorder {
    stream = null;
    mediaRecorder = null;
    audioChunks = [];
    audioBlob = null;
    audioUrl = null;
    audio = null;
    forceStop = false;

    constructor() {
        return new Promise(resolve => {
            navigator.mediaDevices.getUserMedia({ audio: true }).then( MediaStream => {
                this.stream = MediaStream;
                this.mediaRecorder = new MediaRecorder(this.stream);
            
                this.audioChunks = [];

                this.mediaRecorder.addEventListener("dataavailable", event => {
                    this.audioChunks.push(event.data);
                });

                resolve(this);
            });

        });
    };

    start = () => this.mediaRecorder.start();

    stop = () => {
        return new Promise(resolve => {
            this.mediaRecorder.addEventListener("stop", () => {
                this.audioBlob = new Blob(this.audioChunks);
                this.audioUrl = URL.createObjectURL(this.audioBlob);

                resolve(this);
            });

            if(this.mediaRecorder.state === 'recording') {
                this.mediaRecorder.stop();
            }
            
            this.stream.getAudioTracks().forEach(function(track) {
                track.stop();
            });
        });
    }
        

    sleep = time => {
        return new Promise(resolve =>  {
            setTimeout(() => {
                if(this.forceStop) {
                    resolve(false);
                }
                resolve(this);
            }, time);
        });
    }; 

}

export default VoiceRecorder;