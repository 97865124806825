import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Logo from './Components/Logo/Logo';
import Navigation from './Components/Navigation/Navigation';
import Welcome from './Components/Welcome/Welcome';
import Home from './Containers/Home/Home';
import Guide from './Components/Guide/Guide';
import Team from './Containers/Team/Team';
import About from './Components/About/About';
import Experience from './Components/Experience/Experience';
import Compositions from './Containers/Compositions/Compositions';

import './App.css';

function App() {
	return (
		<div className="App">
			<header>
				<div className="navigation-wrapper">
					<Logo/>
					<Navigation/>
				</div>
			</header>
			<div className="content">
				<Switch>
					<Route path="/" exact component={ Welcome } />
					<Route path="/concert" component={ Home } />
					<Route path="/guide" component={ Guide } />
					<Route path="/team" component={ Team } />
					<Route path="/about" component={ About } />
					<Route path="/compositions" component={ Compositions } />
					<Route path="/experience" component={ Experience } />
				</Switch>
			</div>
		</div>
	);
}

export default App;
